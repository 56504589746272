import { COLOR_PROP_PREFIX, DEFAULT_CUSTOM_PROP_SELECTOR } from '../lib/consts';

import type { BaseColorValue, ColorAliasVar } from '../types/color';
import type { Site } from '@haaretz/s-types';

const HDC_BRAND_LM = '75,78,241';
const HDC_BRAND_DM = '123,138,251';

const HTZ_BRAND_LM = '34,110,233';
const HTZ_BRAND_DM = '69,149,255';

const TM_BRAND_LM = '2,135,89';
const TM_BRAND_DM = '74,205,159';

// Define color for htz here and use them to set mandatory
// base color keys in the `BaseColor` and `ColorAlias` types.
//
// Their values is validated against `BaseColorValue` and
// `ColorAliasVar`, respectively, further down the file,
// when they are spread into `htz[DEFAULT_CUSTOM_PROP_SELECTOR]`
// in `colorPalette`.
const htzBaseColors = {
  // Brand colors
  hdcBrand: HDC_BRAND_LM,
  htzBrand: HTZ_BRAND_LM,
  tmBrand: TM_BRAND_LM,

  neutral100: '255,255,255',
  neutral150: '249,249,249',
  neutral200: '243,243,243',
  neutral300: '231,231,231',
  neutral400: '219,219,219',
  neutral500: '207,207,207',
  neutral600: '184,184,184',
  neutral700: '161,161,161',
  neutral800: '138,138,138',
  neutral900: '115,115,115',
  neutral1000: '91,91,91',
  neutral1100: '68,68,68',
  neutral1200: '45,45,45',
  neutral1300: '25,25,25',
  neutral1400: '5,5,5',

  primary100: '241,245,252',
  primary200: '231,239,252',
  primary300: '220,233,253',
  primary400: '207,223,249',
  primary500: '179,207,252',
  primary600: '149,188,251',
  primary700: '119,168,249',
  primary800: '85,147,249',
  primary900: '49,124,248',
  primary1000: HTZ_BRAND_LM,
  primary1100: '18,97,219',
  primary1200: '6,78,184',
  primary1300: '1,56,141',
  primary1400: '3,38,95',
  primary1500: '2,18,51',

  secondary100: '254,240,241',
  secondary200: '252,226,228',
  secondary300: '251,210,215',
  secondary400: '249,182,188',
  secondary500: '247,132,141',
  secondary600: '245,86,98',
  secondary700: '242,43,57',
  secondary800: '231,4,20',
  secondary900: '197,9,22',
  secondary1000: '163,12,21',
  secondary1100: '116,6,13',
  secondary1200: '70,2,6',
  secondary1300: '23,0,2',

  tertiary100: '255,254,243',
  tertiary200: '255,252,210',
  tertiary300: '255,250,176',
  tertiary400: '255,247,141',
  tertiary500: '255,243,106',
  tertiary600: '255,238,71',
  tertiary700: '255,232,35',
  tertiary800: '255,223,0',
  tertiary900: '237,201,0',
  tertiary1000: '208,171,0',
  tertiary1100: '153,121,0',
  tertiary1150: '128,101,0',

  quaternary100: '237,255,239',
  quaternary200: '218,246,219',
  quaternary300: '201,237,200',
  quaternary400: '174,222,171',
  quaternary500: '127,192,125',
  quaternary600: '88,163,86',
  quaternary700: '57,133,54',
  quaternary800: '35,107,44',
  quaternary900: '20,82,35',
  quaternary1000: '10,56,26',
  quaternary1100: '4,32,16',
  quaternary1200: '0,5,3',

  sales100: '254,246,232',
  sales200: '252,239,202',
  sales300: '251,231,170',
  sales400: '250,213,124',
  sales500: '247,180,57',
  sales600: '246,150,0',
  sales700: '212,127,1',
  sales800: '173,106,5',
  sales900: '135,85,9',
  sales1000: '97,63,12',
  sales1100: '59,40,11',
  sales1200: '20,14,6',

  promoted300: '189,19,135',
  promoted800: '254,136,214',
} as const;

const htzColorAliases = {
  articlesSeriesLinks: alias('primary1000'),
  pageBg: alias('neutral200'),
  contentBg: alias('neutral100'),
  bodyText: alias('neutral1200'),
  bodyTextNegative: alias('neutral100'),
  shadow: alias('neutral1300'),
  cardEmphasis: alias('neutral1200'),
  cardBrand: alias('primary100'),
  cardGiftSubscription: alias('primary100'),
  cardGiftSubscriptionHover: alias('primary200'),
  cardOutbrain: alias('neutral200'),
  selection: alias('tertiary700'),
  selectedText: alias('neutral1200'),
  chipPressedBg: alias('primary200'),
  chipPressedBorder: alias('primary400'),
  exclusive: alias('secondary1000'),
  featBlockModalBackdropMobile: alias('neutral1100'),
  footerBg: alias('neutral1200'),
  footerColor: alias('neutral100'),
  lightIconOnDarkSurface: alias('neutral150'),
  lightTextOnDarkSurface: alias('neutral100'),
  fullCoverageListBgGradStart: alias('neutral1200'),
  fullCoverageListBgGradEnd: alias('neutral200'),
  toggleCheckedBg: alias('neutral100'),
  safePurchaseBg: alias('neutral100'),
  breadcrumbAccent: alias('neutral1400'),
  breadcrumb: alias('neutral1200'),
  newsletterRegGradStart: alias('primary200'),
  newsletterRegGradEnd: alias('neutral400'),
  momListBg: alias('primary300'),
  popoverBorder: alias('neutral300'),
  popoverBg: alias('neutral100'),
  popoverInverseColor: alias('neutral100'),
  popoverInverseBg: alias('neutral1000'),
  topicHeadDivider: alias('primary400'),
  topNewsSection: alias('secondary1000'),
  tldrBtn: alias('tertiary700'),
  tldrBtnActive: alias('tertiary900'),
  tldrIcon: alias('neutral1100'),
  salesBtn: alias('sales500'),
  surfaceDarkAllModes: alias('neutral1200'),
  textLightAllModes: alias('neutral150'),
  textDarkAllModes: alias('neutral1200'),
  teaserGallerySelectedDot: alias('neutral100'),
  teaserGalleryDots: alias('neutral400'),
  markBg: alias('primary200'),
  sportsMatchEventsBottomBorder: alias('neutral500'),
  blockingElementGradStart: alias('neutral100'),
  blockingElementGradEnd: alias('primary100'),
  blockingElementSaleGradStart: alias('neutral100'),
  blockingElementSaleGradMid: alias('secondary200'),
  blockingElementSaleGradEnd: alias('primary400'),
  rainbowIllustrationBrandLight: alias('neutral200'),
  rainbowIllustrationBrandMidLight: alias('neutral400'),
  rainbowBottomRulerBg1: alias('primary200'),
  rainbowBottomRulerBgMobile1: alias('primary300'),
  rainbowBottomRulerBg2: alias('tertiary200'),
  rainbowBottomRulerBgMobile2: alias('tertiary300'),
  rainbowBottomRulerBg3: alias('neutral150'),
  rainbowBottomRulerBgMobile3: alias('neutral200'),
  rainbowBottomRulerBg4: alias('secondary100'),
  rainbowBottomRulerBgMobile4: alias('secondary200'),
  rainbowBottomRulerSale1GradStart: alias('secondary200'),
  rainbowBottomRulerSale1GradEnd: alias('primary400'),
  topStripBgAcquisition1: alias('primary1000'),
  topStripBgSubscribers1: alias('primary200'),
  bgLightAllModes: alias('neutral100'),
  positiveMarketsBarMobile: alias('primary500'),
  negativeMarketsBarMobile: alias('secondary500'),
  liveBlogBreakingNewsBg: alias('neutral100'),
  positiveMarketsBar: alias('quaternary700'),
  positiveOnDarkSurface: alias('quaternary500'),
  negativeOnDarkSurface: alias('secondary500'),

  weekendListGradientStart: alias('tertiary500'),
  weekendListGradientMiddle: alias('tertiary400'),
  weekendListGradientEnd: alias('neutral200'),
  sheliIconColor: alias('primary100'),

  // Promotion Page
  ppSafePurchaseBg: alias('neutral100'),
  ppSpecialOfferBgGStart: alias('sales400'),
  ppSpecialOfferBgGEnd: alias('secondary400'),
  ppRibbonFg: alias('quaternary700'),
  ppRibbonFold: alias('quaternary800'),
  bestOfferOfferBg: alias('primary100'),
  progressBarCircle: alias('primary400'),
  b2bGradientEnd: alias('primary200'),

  // Magazine Gradients
  magVenusGradStart: alias('primary400'),
  magVenusGradEnd: alias('secondary200'),
  magAthenaGradStart: alias('sales200'),
  magAthenaGradEnd: alias('secondary400'),
  magHerraGradStart: alias('quaternary300'),
  magHerraGradEnd: alias('primary200'),
  magNeutralGradStart: alias('primary500'),
  magNeutralGradEnd: alias('neutral400'),
  magYellow: alias('tertiary500'),
  magBlack: alias('neutral1200'),

  // Magazine Header
  magHeadFgBlack: alias('neutral1200'),
  magHeadFgWhite: alias('neutral150'),
  magHeadIconHover: alias('primary1300'),
  magHeadIconHoverNegative: alias('primary600'),
  magHeadBreadcrumb: alias('neutral1100'),
  magHeadBreadcrumbWhite: alias('neutral300'),
  magHeadBreadcrumbActiveWhite: alias('neutral150'),

  // Infographic Components
  infoChatUserPlaceholder: alias('primary1000'),
  infoTimeline: alias('primary300'),
  infoTimelineDotBg: alias('neutral100'),
  infoTimelineDot: alias('primary800'),

  // Reading list
  readingListStartGradient: alias('primary200'),
  readingListEndGradient: alias('neutral150'),

  // Search
  searchBgGradientStart: alias('primary200'),
  searchBgGradientEnd: alias('neutral150'),

  // Masthead
  mastheadTextColor: alias('neutral1300'),
  mastheadTextUnderline: alias('primary600'),
  mastheadGradient: alias('primary200'),

  // Rainbow Components
  rainbowModalMobileBackdrop: alias('neutral1100'),
  rainbowModalBackdrop: alias('neutral100'),

  featureBlockTmReadingBg: alias('neutral1200'),
  featureBlockTmReadingFg: alias('neutral100'),

  // Black lists
  blackListBg: alias('neutral1300'),
  blackListColor: alias('neutral100'),
  blackListExclusive: alias('secondary1000'),

  slimPlaceholder: alias('neutral300'),
  slimPlaceholerGradient: alias('neutral600'),
  slimLightBlueInAllModes: alias('primary500'),

  liveBlogHeaderBackground: alias('neutral1300'),
  liveBlogHeader: alias('neutral200'),
  liveBlogBreadcrumbs: alias('primary800'),
  liveBlogKicker: alias('secondary600'),
  liveBlogActionBarIcon: alias('neutral300'),

  // Face Article
  faceArticleBgBtn: alias('neutral100'),
  faceArticleActiveBgBtn: alias('tertiary800'),
  faceArticleColor: alias('neutral1200'),

  notForQuoteBg: alias('primary300'),
  notForQuoteOpenElementBg: alias('tertiary800'),
  notForQuoteOpenElementTitle: alias('primary1100'),
  notForQuoteOpenElementMainBg: alias('neutral150'),

  midrugBtn: alias('primary600'),

  timelineGradStart: alias('primary500'),
  timelineGradEnd: alias('neutral400'),
} as const;

/**
 * A union of valid base color names
 * @public
 */
export type BaseColor = keyof typeof htzBaseColors;
/**
 * A union of valid color-alias names
 * @public
 */
export type ColorAlias = keyof typeof htzColorAliases;

/**
 * An array containing the names of all base colors
 * @public
 */
export const baseColorNames = Object.keys(htzBaseColors) as Array<BaseColor>;
/**
 * An array containing the names of all color aliases
 * @public
 */
export const colorAliasesNames = Object.keys(htzColorAliases) as Array<ColorAlias>;

/**
 * A record of all valid base colors and their values
 * @public
 */
export type BaseColorsPalette = Record<BaseColor, BaseColorValue>;

/**
 * A partial record of all valid base colors and their values
 * @public
 */
export type BaseColorsPaletteSubset = Partial<BaseColorsPalette>;

/**
 * A record of all valid color aliases and their values.
 *
 * Color aliases should only be used when there is a need
 * to use a different base color between dark-mode and the default.
 *
 * @public
 */
export type ColorAliasesPalette = Record<ColorAlias, ColorAliasVar>;

/**
 * A Site's color palette containing all color modes
 * @public
 */
export type SitePalette = {
  /** The default mode's palette */
  [DEFAULT_CUSTOM_PROP_SELECTOR]: BaseColorsPalette & ColorAliasesPalette;
  /** Dark mode palette */
  darkMode?: BaseColorsPaletteSubset & ColorAliasesPalette;
};

/**
 * A Record of the color palettes of all sites
 * @public
 */
export type ColorPalette = Record<Site, SitePalette>;

///////////////
//  PALETTE  //
///////////////

/**
 * The color palettes of all sites
 * @public
 */
const colorPalette: ColorPalette = {
  /** The Haaretz color palettes */
  htz: {
    [DEFAULT_CUSTOM_PROP_SELECTOR]: {
      ...htzBaseColors,
      ...htzColorAliases,
    },
    darkMode: {
      // Brand colors
      hdcBrand: HDC_BRAND_DM,
      htzBrand: HTZ_BRAND_DM,
      tmBrand: TM_BRAND_DM,

      // Base colors
      neutral100: '5,5,5',
      neutral150: '25,25,25',
      neutral200: '45,45,45',
      neutral300: '68,68,68',
      neutral400: '91,91,91',
      neutral500: '115,115,115',
      neutral600: '138,138,138',
      neutral700: '161,161,161',
      neutral800: '184,184,184',
      neutral900: '207,207,207',
      neutral1000: '219,219,219',
      neutral1100: '231,231,231',
      neutral1200: '243,243,243',
      neutral1300: '249,249,249',
      neutral1400: '255,255,255',

      primary100: '0,0,0',
      primary200: '5,14,29',
      primary300: '8,19,38',
      primary400: '14,40,83',
      primary500: '17,59,127',
      primary600: '19,79,176',
      primary700: '17,96,224',
      primary800: '11,104,255',
      primary900: '33,123,255',
      primary1000: HTZ_BRAND_DM,
      primary1100: '103,171,255',
      primary1200: '137,191,255',
      primary1300: '169,210,255',
      primary1400: '200,227,255',
      primary1500: '217,236,255',

      secondary100: '82,14,14',
      secondary200: '98,16,16',
      secondary300: '114,19,18',
      secondary400: '135,28,25',
      secondary500: '157,38,33',
      secondary600: '179,49,42',
      secondary700: '200,60,49',
      secondary800: '222,71,58',
      secondary900: '254,93,76',
      secondary1000: '254,120,110',
      secondary1100: '255,145,140',
      secondary1200: '255,168,166',
      secondary1300: '255,189,189',

      tertiary100: '128,101,0',
      tertiary200: '153,121,0',
      tertiary300: '208,171,0',
      tertiary400: '237,201,0',
      tertiary500: '255,223,0',
      tertiary600: '255,232,35',
      tertiary700: '255,238,71',
      tertiary800: '255,243,106',
      tertiary900: '255,247,141',
      tertiary1000: '255,250,176',
      tertiary1100: '255,252,210',
      tertiary1150: '255,254,243',

      quaternary100: '0,5,3',
      quaternary200: '4,32,16',
      quaternary300: '10,56,26',
      quaternary400: '20,82,35',
      quaternary500: '35,107,44',
      quaternary600: '57,133,54',
      quaternary700: '88,163,86',
      quaternary800: '127,192,125',
      quaternary900: '174,222,171',
      quaternary1000: '201,237,200',
      quaternary1100: '218,246,219',
      quaternary1200: '237,255,239',

      sales100: '20,14,6',
      sales200: '59,40,11',
      sales300: '97,63,12',
      sales400: '135,85,9',
      sales500: '173,106,5',
      sales600: '212,127,1',
      sales700: '246,150,0',
      sales800: '247,180,57',
      sales900: '250,213,124',
      sales1000: '251,231,170',
      sales1100: '252,239,202',
      sales1200: '254,246,232',

      promoted300: '254,136,214',
      promoted800: '189,19,135',
      // Color aliases
      articlesSeriesLinks: alias('primary1100'),
      pageBg: alias('neutral100'),
      contentBg: alias('neutral150'),
      sportsMatchEventsBottomBorder: alias('neutral400'),
      bodyText: alias('neutral1200'),
      bodyTextNegative: alias('neutral200'),
      shadow: alias('neutral100'),
      cardEmphasis: alias('neutral300'),
      cardBrand: alias('primary400'),
      cardGiftSubscription: alias('primary400'),
      cardGiftSubscriptionHover: alias('primary500'),
      cardOutbrain: alias('neutral300'),
      selection: alias('tertiary200'),
      selectedText: alias('neutral150'),
      chipPressedBg: alias('primary300'),
      chipPressedBorder: alias('primary500'),
      exclusive: alias('secondary900'),
      featBlockModalBackdropMobile: alias('neutral300'),
      footerBg: alias('neutral200'),
      footerColor: alias('neutral1200'),
      fullCoverageListBgGradStart: alias('neutral300'),
      fullCoverageListBgGradEnd: alias('neutral400'),
      toggleCheckedBg: alias('neutral200'),
      safePurchaseBg: alias('neutral200'),
      breadcrumbAccent: alias('neutral100'),
      breadcrumb: alias('neutral200'),
      newsletterRegGradStart: alias('primary400'),
      newsletterRegGradEnd: alias('neutral200'),
      momListBg: alias('primary300'),
      popoverBorder: alias('neutral400'),
      popoverBg: alias('neutral150'),
      popoverInverseColor: alias('neutral100'),
      popoverInverseBg: alias('neutral1000'),
      topicHeadDivider: alias('primary600'),
      topNewsSection: alias('secondary900'),
      tldrBtn: alias('primary1000'),
      tldrBtnActive: alias('primary800'),
      tldrIcon: alias('neutral200'),
      salesBtn: alias('sales800'),
      surfaceDarkAllModes: alias('neutral200'),
      textLightAllModes: alias('neutral1300'),
      textDarkAllModes: alias('neutral200'),
      teaserGallerySelectedDot: alias('neutral1400'),
      teaserGalleryDots: alias('neutral1000'),
      lightIconOnDarkSurface: alias('neutral900'),
      lightTextOnDarkSurface: alias('neutral1000'),
      markBg: alias('primary400'),
      blockingElementGradStart: alias('neutral150'),
      blockingElementGradEnd: alias('primary400'),
      blockingElementSaleGradStart: alias('neutral150'),
      blockingElementSaleGradMid: alias('primary500'),
      blockingElementSaleGradEnd: alias('secondary1100'),
      rainbowIllustrationBrandLight: alias('neutral400'),
      rainbowIllustrationBrandMidLight: alias('neutral600'),
      rainbowBottomRulerBg1: alias('primary200'),
      rainbowBottomRulerBgMobile1: alias('primary300'),
      rainbowBottomRulerBg2: alias('primary1100'),
      rainbowBottomRulerBgMobile2: alias('primary1100'),
      rainbowBottomRulerBg3: alias('neutral150'),
      rainbowBottomRulerBgMobile3: alias('neutral200'),
      rainbowBottomRulerBg4: alias('primary400'),
      rainbowBottomRulerBgMobile4: alias('primary500'),
      rainbowBottomRulerSale1GradStart: alias('primary500'),
      rainbowBottomRulerSale1GradEnd: alias('secondary1100'),
      topStripBgAcquisition1: alias('primary1000'),
      topStripBgSubscribers1: alias('primary200'),
      weekendListGradientStart: alias('primary600'),
      bgLightAllModes: alias('neutral1400'),
      weekendListGradientMiddle: alias('primary400'),
      weekendListGradientEnd: alias('primary400'),
      positiveMarketsBarMobile: alias('primary1000'),
      negativeMarketsBarMobile: alias('secondary1000'),
      liveBlogBreakingNewsBg: alias('neutral150'),
      sheliIconColor: alias('neutral300'),
      positiveMarketsBar: alias('quaternary800'),
      positiveOnDarkSurface: alias('quaternary700'),
      negativeOnDarkSurface: alias('secondary900'),

      // Promotion Page
      ppSafePurchaseBg: alias('neutral200'),
      ppSpecialOfferBgGStart: alias('primary1100'),
      ppSpecialOfferBgGEnd: alias('secondary1000'),
      ppRibbonFg: alias('quaternary700'),
      ppRibbonFold: alias('quaternary600'),
      bestOfferOfferBg: alias('primary300'),
      progressBarCircle: alias('primary500'),
      b2bGradientEnd: alias('primary200'),

      // Magazine Gradients
      magVenusGradStart: alias('primary1100'),
      magVenusGradEnd: alias('secondary1100'),
      magAthenaGradStart: alias('sales800'),
      magAthenaGradEnd: alias('secondary1300'), // !
      magHerraGradStart: alias('primary1100'),
      magHerraGradEnd: alias('quaternary800'),
      magNeutralGradStart: alias('primary1400'),
      magNeutralGradEnd: alias('neutral700'),
      magYellow: alias('tertiary800'),
      magBlack: alias('neutral200'),

      // Magazine Header
      magHeadFgBlack: alias('neutral200'),
      magHeadFgWhite: alias('neutral1300'),
      magHeadIconHover: alias('primary500'),
      magHeadIconHoverNegative: alias('primary1200'),
      magHeadBreadcrumb: alias('neutral200'),
      magHeadBreadcrumbWhite: alias('neutral1300'),
      magHeadBreadcrumbActiveWhite: alias('neutral1100'),

      // Infographic Components
      infoChatUserPlaceholder: alias('primary800'),
      infoTimeline: alias('primary1400'),
      infoTimelineDotBg: alias('neutral150'),
      infoTimelineDot: alias('primary1000'),
      // reading list
      readingListStartGradient: alias('primary400'),
      readingListEndGradient: alias('neutral150'),
      // Search
      searchBgGradientStart: alias('primary200'),
      searchBgGradientEnd: alias('neutral100'),

      // Masthead
      mastheadTextColor: alias('neutral150'),
      mastheadTextUnderline: alias('primary600'),
      mastheadGradient: alias('primary400'),

      // Rainbow Components
      rainbowModalMobileBackdrop: alias('neutral150'),
      rainbowModalBackdrop: alias('neutral150'),

      featureBlockTmReadingBg: alias('neutral900'),
      featureBlockTmReadingFg: alias('neutral200'),

      // Black lists
      blackListBg: alias('neutral1300'),
      blackListColor: alias('neutral100'),
      blackListExclusive: alias('secondary900'),

      slimPlaceholder: alias('neutral200'),
      slimPlaceholerGradient: alias('neutral400'),
      slimLightBlueInAllModes: alias('primary1100'),

      liveBlogHeaderBackground: alias('neutral150'),
      liveBlogHeader: alias('neutral1200'),
      liveBlogBreadcrumbs: alias('primary1000'),
      liveBlogKicker: alias('secondary900'),
      liveBlogActionBarIcon: alias('neutral1100'),

      // Face Article
      faceArticleBgBtn: alias('neutral1400'),
      faceArticleActiveBgBtn: alias('tertiary500'),
      faceArticleColor: alias('neutral200'),

      notForQuoteBg: alias('primary400'),
      notForQuoteOpenElementBg: alias('primary700'),
      notForQuoteOpenElementTitle: alias('primary1000'),
      notForQuoteOpenElementMainBg: alias('neutral200'),

      midrugBtn: alias('primary700'),

      timelineGradStart: alias('primary1500'),
      timelineGradEnd: alias('neutral600'),
    },
  },
  /** The TheMarker color palettes */
  tm: {
    [DEFAULT_CUSTOM_PROP_SELECTOR]: {
      // Brand colors
      hdcBrand: HDC_BRAND_LM,
      htzBrand: HTZ_BRAND_LM,
      tmBrand: TM_BRAND_LM,

      // Base colors
      neutral100: '255,255,255',
      neutral150: '249,249,249',
      neutral200: '243,243,243',
      neutral300: '231,231,231',
      neutral400: '219,219,219',
      neutral500: '207,207,207',
      neutral600: '184,184,184',
      neutral700: '161,161,161',
      neutral800: '138,138,138',
      neutral900: '115,115,115',
      neutral1000: '91,91,91',
      neutral1100: '68,68,68',
      neutral1200: '45,45,45',
      neutral1300: '25,25,25',
      neutral1400: '5,5,5',

      primary100: '225,239,234',
      primary200: '180,229,212',
      primary300: '153,225,200',
      primary400: '126,220,187',
      primary500: '100,214,174',
      primary600: '74,205,159',
      primary700: '48,191,142',
      primary800: '24,165,116',
      primary900: '12,150,102',
      primary1000: TM_BRAND_LM,
      primary1100: '11,112,77',
      primary1200: '14,82,58',
      primary1300: '13,51,38',
      primary1400: '8,25,19',
      primary1500: '0,0,0',

      secondary100: '254,240,241',
      secondary200: '252,226,228',
      secondary300: '251,210,215',
      secondary400: '249,182,188',
      secondary500: '247,132,141',
      secondary600: '245,86,98',
      secondary700: '242,43,57',
      secondary800: '231,4,20',
      secondary900: '197,9,22',
      secondary1000: '163,12,21',
      secondary1100: '116,6,13',
      secondary1200: '70,2,6',
      secondary1300: '23,0,2',

      tertiary100: '255,254,243',
      tertiary200: '255,252,210',
      tertiary300: '255,250,176',
      tertiary400: '255,247,141',
      tertiary500: '255,243,106',
      tertiary600: '255,238,71',
      tertiary700: '255,232,35',
      tertiary800: '255,223,0',
      tertiary900: '237,201,0',
      tertiary1000: '208,171,0',
      tertiary1100: '153,121,0',
      tertiary1150: '128,101,0',

      quaternary100: '237,255,239',
      quaternary200: '218,246,219',
      quaternary300: '201,237,200',
      quaternary400: '174,222,171',
      quaternary500: '127,192,125',
      quaternary600: '88,163,86',
      quaternary700: '57,133,54',
      quaternary800: '35,107,44',
      quaternary900: '20,82,35',
      quaternary1000: '10,56,26',
      quaternary1100: '4,32,16',
      quaternary1200: '0,5,3',

      sales100: '254,246,232',
      sales200: '252,239,202',
      sales300: '251,231,170',
      sales400: '250,213,124',
      sales500: '247,180,57',
      sales600: '246,150,0',
      sales700: '212,127,1',
      sales800: '173,106,5',
      sales900: '135,85,9',
      sales1000: '97,63,12',
      sales1100: '59,40,11',
      sales1200: '20,14,6',

      promoted300: '189,19,135',
      promoted800: '254,136,214',

      // Color aliases
      articlesSeriesLinks: alias('primary1000'),
      pageBg: alias('neutral200'),
      contentBg: alias('neutral100'),
      bodyText: alias('neutral1200'),
      bodyTextNegative: alias('neutral100'),
      shadow: alias('neutral1300'),
      cardEmphasis: alias('neutral1200'),
      cardBrand: alias('primary100'),
      cardGiftSubscription: alias('primary100'),
      cardGiftSubscriptionHover: alias('primary200'),
      cardOutbrain: alias('neutral200'),
      selection: alias('tertiary700'),
      selectedText: alias('neutral1200'),
      chipPressedBg: alias('primary200'),
      chipPressedBorder: alias('primary400'),
      exclusive: alias('secondary1000'),
      lightIconOnDarkSurface: alias('neutral150'),
      lightTextOnDarkSurface: alias('neutral100'),
      featBlockModalBackdropMobile: alias('neutral1100'),
      footerBg: alias('neutral1200'),
      footerColor: alias('neutral100'),
      fullCoverageListBgGradStart: alias('neutral1200'),
      fullCoverageListBgGradEnd: alias('neutral200'),
      toggleCheckedBg: alias('neutral100'),
      safePurchaseBg: alias('neutral200'),
      breadcrumbAccent: alias('neutral1400'),
      breadcrumb: alias('neutral1200'),
      newsletterRegGradStart: alias('primary200'),
      newsletterRegGradEnd: alias('neutral400'),
      momListBg: alias('primary200'),
      popoverBorder: alias('neutral300'),
      popoverBg: alias('neutral100'),
      popoverInverseColor: alias('neutral100'),
      popoverInverseBg: alias('neutral1000'),
      topicHeadDivider: alias('primary400'),
      topNewsSection: alias('secondary1100'),
      tldrBtn: alias('primary500'),
      tldrBtnActive: alias('primary700'),
      tldrIcon: alias('neutral1100'),
      salesBtn: alias('sales500'),
      surfaceDarkAllModes: alias('neutral1200'),
      textLightAllModes: alias('neutral150'),
      textDarkAllModes: alias('neutral1200'),
      teaserGallerySelectedDot: alias('neutral100'),
      teaserGalleryDots: alias('neutral400'),
      markBg: alias('primary200'),
      blockingElementGradStart: alias('neutral100'),
      blockingElementGradEnd: alias('primary100'),
      blockingElementSaleGradStart: alias('neutral100'),
      blockingElementSaleGradMid: alias('secondary200'),
      blockingElementSaleGradEnd: alias('primary300'),
      sportsMatchEventsBottomBorder: alias('neutral500'),
      rainbowIllustrationBrandLight: alias('neutral200'),
      rainbowIllustrationBrandMidLight: alias('neutral400'),
      rainbowBottomRulerBg1: alias('neutral150'),
      rainbowBottomRulerBgMobile1: alias('neutral300'),
      rainbowBottomRulerBg2: alias('primary100'),
      rainbowBottomRulerBgMobile2: alias('primary200'),
      rainbowBottomRulerBg3: alias('neutral150'),
      rainbowBottomRulerBgMobile3: alias('neutral200'),
      rainbowBottomRulerBg4: alias('neutral1200'),
      rainbowBottomRulerBgMobile4: alias('neutral1200'),
      rainbowBottomRulerSale1GradStart: alias('secondary200'),
      rainbowBottomRulerSale1GradEnd: alias('primary300'),
      topStripBgAcquisition1: alias('neutral1200'),
      topStripBgSubscribers1: alias('primary100'),
      weekendListGradientStart: alias('primary500'),
      bgLightAllModes: alias('neutral100'),
      weekendListGradientMiddle: alias('primary400'),
      weekendListGradientEnd: alias('primary100'),
      positiveMarketsBarMobile: alias('primary500'),
      negativeMarketsBarMobile: alias('secondary500'),
      liveBlogBreakingNewsBg: alias('neutral100'),
      sheliIconColor: alias('primary100'),
      positiveMarketsBar: alias('quaternary700'),
      positiveOnDarkSurface: alias('quaternary500'),
      negativeOnDarkSurface: alias('secondary500'),

      // Promotion Page
      ppSafePurchaseBg: alias('neutral100'),
      ppSpecialOfferBgGStart: alias('sales300'),
      ppSpecialOfferBgGEnd: alias('primary600'),
      ppRibbonFg: alias('quaternary700'),
      ppRibbonFold: alias('quaternary800'),
      bestOfferOfferBg: alias('primary100'),
      progressBarCircle: alias('primary400'),
      b2bGradientEnd: alias('primary100'),

      // Magazine Gradients
      magVenusGradStart: alias('sales100'),
      magVenusGradEnd: alias('primary200'),
      magAthenaGradStart: alias('sales300'),
      magAthenaGradEnd: alias('secondary200'),
      magHerraGradStart: alias('primary400'),
      magHerraGradEnd: alias('quaternary500'),
      magNeutralGradStart: alias('neutral300'),
      magNeutralGradEnd: alias('neutral600'),
      magYellow: alias('tertiary500'),
      magBlack: alias('neutral1200'),

      // Magazine Header
      magHeadFgBlack: alias('neutral1200'),
      magHeadFgWhite: alias('neutral150'),
      magHeadIconHover: alias('primary1300'),
      magHeadIconHoverNegative: alias('primary600'),
      magHeadBreadcrumb: alias('neutral1100'),
      magHeadBreadcrumbWhite: alias('neutral300'),
      magHeadBreadcrumbActiveWhite: alias('neutral150'),

      // Infogrpahic Components
      infoChatUserPlaceholder: alias('primary1000'),
      infoTimeline: alias('primary300'),
      infoTimelineDotBg: alias('neutral100'),
      infoTimelineDot: alias('primary800'),

      // Reading list
      readingListStartGradient: alias('primary100'),
      readingListEndGradient: alias('neutral150'),

      // Search
      searchBgGradientStart: alias('primary200'),
      searchBgGradientEnd: alias('neutral150'),

      // Masthead
      mastheadTextColor: alias('neutral1300'),
      mastheadTextUnderline: alias('primary200'),
      mastheadGradient: alias('primary200'),

      // Rainbow Components
      rainbowModalMobileBackdrop: alias('neutral1100'),
      rainbowModalBackdrop: alias('neutral100'),

      featureBlockTmReadingBg: alias('neutral1200'),
      featureBlockTmReadingFg: alias('neutral100'),

      // Black lists
      blackListBg: alias('neutral1300'),
      blackListColor: alias('neutral100'),
      blackListExclusive: alias('secondary1000'),

      slimPlaceholder: alias('neutral300'),
      slimPlaceholerGradient: alias('neutral600'),
      slimLightBlueInAllModes: alias('primary500'),

      liveBlogHeaderBackground: alias('neutral1300'),
      liveBlogHeader: alias('neutral200'),
      liveBlogBreadcrumbs: alias('primary800'),
      liveBlogKicker: alias('secondary600'),
      liveBlogActionBarIcon: alias('neutral300'),

      // Face Article
      faceArticleBgBtn: alias('neutral100'),
      faceArticleActiveBgBtn: alias('neutral500'),
      faceArticleColor: alias('neutral1200'),

      notForQuoteBg: alias('primary300'),
      notForQuoteOpenElementBg: alias('tertiary800'),
      notForQuoteOpenElementTitle: alias('primary1100'),
      notForQuoteOpenElementMainBg: alias('neutral150'),

      midrugBtn: alias('primary600'),

      timelineGradStart: alias('primary500'),
      timelineGradEnd: alias('neutral400'),
    },
    darkMode: {
      // Brand colors
      hdcBrand: HDC_BRAND_DM,
      htzBrand: HTZ_BRAND_DM,
      tmBrand: TM_BRAND_DM,

      neutral100: '5,5,5',
      neutral150: '25,25,25',
      neutral200: '45,45,45',
      neutral300: '68,68,68',
      neutral400: '91,91,91',
      neutral500: '115,115,115',
      neutral600: '138,138,138',
      neutral700: '161,161,161',
      neutral800: '184,184,184',
      neutral900: '207,207,207',
      neutral1000: '219,219,219',
      neutral1100: '231,231,231',
      neutral1200: '243,243,243',
      neutral1300: '249,249,249',
      neutral1400: '255,255,255',

      primary100: '0,0,0',
      primary200: '8,25,19',
      primary300: '13,51,38',
      primary400: '14,82,58',
      primary500: '11,112,77',
      primary600: '2,135,89',
      primary700: '12,150,102',
      primary800: '24,165,116',
      primary900: '48,191,142',
      primary1000: TM_BRAND_DM,
      primary1100: '100,214,174',
      primary1200: '126,220,187',
      primary1300: '153,225,200',
      primary1400: '180,229,212',
      primary1500: '207,232,223',

      secondary100: '82,14,14',
      secondary200: '98,16,16',
      secondary300: '114,19,18',
      secondary400: '135,28,25',
      secondary500: '157,38,33',
      secondary600: '179,49,42',
      secondary700: '200,60,49',
      secondary800: '222,71,58',
      secondary900: '254,93,76',
      secondary1000: '254,120,110',
      secondary1100: '255,145,140',
      secondary1200: '255,168,166',
      secondary1300: '255,189,189',

      tertiary100: '128,101,0',
      tertiary200: '153,121,0',
      tertiary300: '208,171,0',
      tertiary400: '237,201,0',
      tertiary500: '255,223,0',
      tertiary600: '255,232,35',
      tertiary700: '255,238,71',
      tertiary800: '255,243,106',
      tertiary900: '255,247,141',
      tertiary1000: '255,250,176',
      tertiary1100: '255,252,210',
      tertiary1150: '255,254,243',

      quaternary100: '0,5,3',
      quaternary200: '4,32,16',
      quaternary300: '10,56,26',
      quaternary400: '20,82,35',
      quaternary500: '35,107,44',
      quaternary600: '57,133,54',
      quaternary700: '88,163,86',
      quaternary800: '127,192,125',
      quaternary900: '174,222,171',
      quaternary1000: '201,237,200',
      quaternary1100: '218,246,219',
      quaternary1200: '237,255,239',

      sales100: '20,14,6',
      sales200: '59,40,11',
      sales300: '97,63,12',
      sales400: '135,85,9',
      sales500: '173,106,5',
      sales600: '212,127,1',
      sales700: '246,150,0',
      sales800: '247,180,57',
      sales900: '250,213,124',
      sales1000: '251,231,170',
      sales1100: '252,239,202',
      sales1200: '254,246,232',

      promoted300: '254,136,214',
      promoted800: '189,19,135',
      // Color aliases
      articlesSeriesLinks: alias('primary1100'),
      pageBg: alias('neutral100'),
      contentBg: alias('neutral150'),
      bodyText: alias('neutral1200'),
      bodyTextNegative: alias('neutral200'),
      shadow: alias('neutral100'),
      cardEmphasis: alias('neutral300'),
      cardBrand: alias('quaternary400'),
      cardGiftSubscription: alias('quaternary400'),
      cardGiftSubscriptionHover: alias('quaternary500'),
      cardOutbrain: alias('neutral300'),
      selection: alias('tertiary200'),
      selectedText: alias('neutral150'),
      chipPressedBg: alias('primary300'),
      chipPressedBorder: alias('primary500'),
      exclusive: alias('secondary900'),
      featBlockModalBackdropMobile: alias('neutral300'),
      footerBg: alias('neutral200'),
      footerColor: alias('neutral1200'),
      fullCoverageListBgGradStart: alias('neutral300'),
      fullCoverageListBgGradEnd: alias('neutral400'),
      toggleCheckedBg: alias('neutral200'),
      safePurchaseBg: alias('neutral100'),
      breadcrumbAccent: alias('neutral100'),
      sportsMatchEventsBottomBorder: alias('neutral400'),
      breadcrumb: alias('neutral200'),
      lightIconOnDarkSurface: alias('neutral900'),
      lightTextOnDarkSurface: alias('neutral1000'),
      momListBg: alias('primary300'),
      newsletterRegGradStart: alias('primary300'),
      newsletterRegGradEnd: alias('neutral300'),
      popoverBorder: alias('neutral400'),
      popoverBg: alias('neutral150'),
      popoverInverseColor: alias('neutral100'),
      popoverInverseBg: alias('neutral1000'),
      topicHeadDivider: alias('primary600'),
      topNewsSection: alias('secondary1100'),
      tldrBtn: alias('primary1000'),
      tldrBtnActive: alias('primary800'),
      tldrIcon: alias('neutral200'),
      salesBtn: alias('sales800'),
      surfaceDarkAllModes: alias('neutral200'),
      textLightAllModes: alias('neutral1300'),
      textDarkAllModes: alias('neutral200'),
      teaserGallerySelectedDot: alias('neutral1400'),
      teaserGalleryDots: alias('neutral1000'),
      markBg: alias('primary400'),
      blockingElementGradStart: alias('neutral150'),
      blockingElementGradEnd: alias('primary300'),
      blockingElementSaleGradStart: alias('neutral150'),
      blockingElementSaleGradMid: alias('primary400'),
      blockingElementSaleGradEnd: alias('secondary1100'),
      rainbowIllustrationBrandLight: alias('neutral400'),
      rainbowIllustrationBrandMidLight: alias('neutral600'),
      rainbowBottomRulerBg1: alias('neutral200'),
      rainbowBottomRulerBgMobile1: alias('neutral200'),
      rainbowBottomRulerBg2: alias('primary300'),
      rainbowBottomRulerBgMobile2: alias('primary400'),
      rainbowBottomRulerBg3: alias('neutral150'),
      rainbowBottomRulerBgMobile3: alias('neutral200'),
      rainbowBottomRulerBg4: alias('neutral1000'),
      rainbowBottomRulerBgMobile4: alias('neutral1000'),
      rainbowBottomRulerSale1GradStart: alias('primary1200'),
      rainbowBottomRulerSale1GradEnd: alias('secondary1100'),
      topStripBgAcquisition1: alias('neutral800'),
      topStripBgSubscribers1: alias('primary200'),
      weekendListGradientStart: alias('primary600'),
      bgLightAllModes: alias('neutral1400'),
      weekendListGradientMiddle: alias('primary400'),
      weekendListGradientEnd: alias('primary200'),
      positiveMarketsBarMobile: alias('primary1000'),
      negativeMarketsBarMobile: alias('secondary1000'),
      liveBlogBreakingNewsBg: alias('neutral150'),
      sheliIconColor: alias('neutral300'),
      positiveMarketsBar: alias('quaternary800'),
      positiveOnDarkSurface: alias('quaternary700'),
      negativeOnDarkSurface: alias('secondary900'),

      // Promotion Page
      ppSafePurchaseBg: alias('neutral200'),
      ppSpecialOfferBgGStart: alias('primary400'),
      ppSpecialOfferBgGEnd: alias('primary800'),
      ppRibbonFg: alias('quaternary700'),
      ppRibbonFold: alias('quaternary600'),
      bestOfferOfferBg: alias('primary300'),
      progressBarCircle: alias('primary500'),
      b2bGradientEnd: alias('primary200'),

      // Magazine Gradients
      magVenusGradStart: alias('sales1000'),
      magVenusGradEnd: alias('primary1000'),
      magAthenaGradStart: alias('sales500'),
      magAthenaGradEnd: alias('secondary1300'),
      magHerraGradStart: alias('primary1300'),
      magHerraGradEnd: alias('quaternary700'),
      magNeutralGradStart: alias('neutral1000'),
      magNeutralGradEnd: alias('neutral700'),
      magYellow: alias('tertiary800'),
      magBlack: alias('neutral150'),

      // Magazine Header
      magHeadFgBlack: alias('neutral200'),
      magHeadFgWhite: alias('neutral1300'),
      magHeadIconHover: alias('primary500'),
      magHeadIconHoverNegative: alias('primary1200'),
      magHeadBreadcrumb: alias('neutral200'),
      magHeadBreadcrumbWhite: alias('neutral1300'),
      magHeadBreadcrumbActiveWhite: alias('neutral1100'),

      // Infographic Components
      infoChatUserPlaceholder: alias('primary800'),
      infoTimeline: alias('primary1400'),
      infoTimelineDotBg: alias('neutral150'),
      infoTimelineDot: alias('primary1000'),

      // Reading list
      readingListStartGradient: alias('primary400'),
      readingListEndGradient: alias('neutral150'),

      // Search
      searchBgGradientStart: alias('primary200'),
      searchBgGradientEnd: alias('neutral100'),

      // Masthead
      mastheadTextColor: alias('neutral150'),
      mastheadTextUnderline: alias('primary200'),
      mastheadGradient: alias('primary200'),

      // Rainbow components
      rainbowModalMobileBackdrop: alias('neutral150'),
      rainbowModalBackdrop: alias('neutral150'),

      featureBlockTmReadingBg: alias('neutral900'),
      featureBlockTmReadingFg: alias('neutral200'),

      // Black lists
      blackListBg: alias('neutral1300'),
      blackListColor: alias('neutral100'),
      blackListExclusive: alias('secondary900'),

      slimPlaceholder: alias('neutral200'),
      slimPlaceholerGradient: alias('neutral400'),
      slimLightBlueInAllModes: alias('primary1100'),

      liveBlogHeaderBackground: alias('neutral150'),
      liveBlogHeader: alias('neutral1200'),
      liveBlogBreadcrumbs: alias('primary1000'),
      liveBlogKicker: alias('secondary900'),
      liveBlogActionBarIcon: alias('neutral1100'),

      // Face Article
      faceArticleBgBtn: alias('neutral1400'),
      faceArticleActiveBgBtn: alias('tertiary800'),
      faceArticleColor: alias('neutral200'),

      notForQuoteBg: alias('primary400'),
      notForQuoteOpenElementBg: alias('primary700'),
      notForQuoteOpenElementTitle: alias('primary1000'),
      notForQuoteOpenElementMainBg: alias('neutral200'),

      midrugBtn: alias('primary700'),

      timelineGradStart: alias('primary1500'),
      timelineGradEnd: alias('neutral600'),
    },
  },
  /** The Haaretz.com color palettes */
  hdc: {
    [DEFAULT_CUSTOM_PROP_SELECTOR]: {
      // Brand colors
      hdcBrand: HDC_BRAND_LM,
      htzBrand: HTZ_BRAND_LM,
      tmBrand: TM_BRAND_LM,

      // Base colors
      neutral100: '255,255,255',
      neutral150: '249,249,249',
      neutral200: '243,243,243',
      neutral300: '231,231,231',
      neutral400: '219,219,219',
      neutral500: '207,207,207',
      neutral600: '184,184,184',
      neutral700: '161,161,161',
      neutral800: '138,138,138',
      neutral900: '115,115,115',
      neutral1000: '91,91,91',
      neutral1100: '68,68,68',
      neutral1200: '45,45,45',
      neutral1300: '25,25,25',
      neutral1400: '5,5,5',

      primary100: '239,245,255',
      primary200: '218,232,255',
      primary300: '196,216,255',
      primary400: '185,206,255',
      primary500: '174,194,255',
      primary600: '149,170,255',
      primary700: '123,142,255',
      primary800: '108,123,255',
      primary900: '93,103,255',
      primary1000: HDC_BRAND_LM,
      primary1100: '63,59,226',
      primary1200: '43,54,177',
      primary1300: '29,47,128',
      primary1400: '18,36,83',
      primary1500: '8,19,38',

      secondary100: '254,240,241',
      secondary200: '252,226,228',
      secondary300: '251,210,215',
      secondary400: '249,182,188',
      secondary500: '247,132,141',
      secondary600: '245,86,98',
      secondary700: '242,43,57',
      secondary800: '231,4,20',
      secondary900: '197,9,22',
      secondary1000: '163,12,21',
      secondary1100: '116,6,13',
      secondary1200: '70,2,6',
      secondary1300: '23,0,2',

      tertiary100: '255,254,243',
      tertiary200: '255,252,210',
      tertiary300: '255,250,176',
      tertiary400: '255,247,141',
      tertiary500: '255,243,106',
      tertiary600: '255,238,71',
      tertiary700: '255,232,35',
      tertiary800: '255,223,0',
      tertiary900: '237,201,0',
      tertiary1000: '208,171,0',
      tertiary1100: '153,121,0',
      tertiary1150: '128,101,0',

      quaternary100: '237,255,239',
      quaternary200: '218,246,219',
      quaternary300: '201,237,200',
      quaternary400: '174,222,171',
      quaternary500: '127,192,125',
      quaternary600: '88,163,86',
      quaternary700: '57,133,54',
      quaternary800: '35,107,44',
      quaternary900: '20,82,35',
      quaternary1000: '10,56,26',
      quaternary1100: '4,32,16',
      quaternary1200: '0,5,3',

      sales100: '254,246,232',
      sales200: '252,239,202',
      sales300: '251,231,170',
      sales400: '250,213,124',
      sales500: '247,180,57',
      sales600: '246,150,0',
      sales700: '212,127,1',
      sales800: '173,106,5',
      sales900: '135,85,9',
      sales1000: '97,63,12',
      sales1100: '59,40,11',
      sales1200: '20,14,6',

      promoted300: '189,19,135',
      promoted800: '254,136,214',
      // Color aliases
      articlesSeriesLinks: alias('primary1000'),
      pageBg: alias('neutral150'),
      contentBg: alias('neutral100'),
      bodyText: alias('neutral1200'),
      bodyTextNegative: alias('neutral100'),
      shadow: alias('neutral1300'),
      cardEmphasis: alias('neutral1200'),
      sportsMatchEventsBottomBorder: alias('neutral500'),
      lightIconOnDarkSurface: alias('neutral150'),
      lightTextOnDarkSurface: alias('neutral100'),
      // TODO: Change this
      cardBrand: alias('sales900'),
      cardGiftSubscription: alias('primary100'),
      cardGiftSubscriptionHover: alias('primary200'),
      cardOutbrain: alias('neutral200'),
      selection: alias('tertiary700'),
      selectedText: alias('neutral1200'),
      chipPressedBg: alias('primary200'),
      chipPressedBorder: alias('primary400'),
      exclusive: alias('secondary1000'),
      featBlockModalBackdropMobile: alias('neutral1100'),
      footerBg: alias('neutral1200'),
      footerColor: alias('neutral100'),
      fullCoverageListBgGradStart: alias('neutral1200'),
      fullCoverageListBgGradEnd: alias('neutral200'),
      toggleCheckedBg: alias('neutral100'),
      safePurchaseBg: alias('neutral100'),
      breadcrumbAccent: alias('neutral1400'),
      breadcrumb: alias('neutral1200'),
      newsletterRegGradStart: alias('primary100'),
      newsletterRegGradEnd: alias('neutral400'),
      momListBg: alias('primary300'),
      popoverBorder: alias('neutral300'),
      popoverBg: alias('neutral100'),
      popoverInverseColor: alias('neutral100'),
      popoverInverseBg: alias('neutral1000'),
      topicHeadDivider: alias('primary400'),
      // TODO: change when hdc design is ready
      tldrBtn: alias('primary1000'),
      topNewsSection: alias('secondary1000'),
      tldrBtnActive: alias('primary800'),
      tldrIcon: alias('neutral1100'),
      salesBtn: alias('sales500'),
      surfaceDarkAllModes: alias('neutral1200'),
      textLightAllModes: alias('neutral150'),
      textDarkAllModes: alias('neutral1200'),
      teaserGallerySelectedDot: alias('neutral100'),
      teaserGalleryDots: alias('neutral400'),
      markBg: alias('primary200'),
      blockingElementGradStart: alias('neutral100'),
      blockingElementGradEnd: alias('primary100'),
      blockingElementSaleGradStart: alias('neutral100'),
      blockingElementSaleGradMid: alias('secondary200'),
      blockingElementSaleGradEnd: alias('primary400'),
      rainbowIllustrationBrandLight: alias('neutral200'),
      rainbowIllustrationBrandMidLight: alias('neutral400'),
      rainbowBottomRulerBg1: alias('primary200'),
      rainbowBottomRulerBgMobile1: alias('primary300'),
      rainbowBottomRulerBg2: alias('tertiary200'),
      rainbowBottomRulerBgMobile2: alias('tertiary300'),
      rainbowBottomRulerBg3: alias('neutral150'),
      rainbowBottomRulerBgMobile3: alias('neutral200'),
      rainbowBottomRulerBg4: alias('secondary100'),
      rainbowBottomRulerBgMobile4: alias('secondary200'),
      rainbowBottomRulerSale1GradStart: alias('secondary200'),
      rainbowBottomRulerSale1GradEnd: alias('primary400'),
      topStripBgAcquisition1: alias('primary1000'),
      topStripBgSubscribers1: alias('primary200'),
      weekendListGradientStart: alias('primary500'),
      bgLightAllModes: alias('neutral100'),
      weekendListGradientMiddle: alias('primary400'),
      weekendListGradientEnd: alias('primary100'),
      positiveMarketsBarMobile: alias('primary500'),
      negativeMarketsBarMobile: alias('secondary500'),
      liveBlogBreakingNewsBg: alias('neutral100'),
      sheliIconColor: alias('primary100'),
      positiveMarketsBar: alias('quaternary700'),
      positiveOnDarkSurface: alias('quaternary500'),
      negativeOnDarkSurface: alias('secondary500'),

      // Promotion Page
      ppSafePurchaseBg: alias('neutral100'),
      ppSpecialOfferBgGStart: alias('primary200'),
      ppSpecialOfferBgGEnd: alias('sales200'),
      ppRibbonFg: alias('quaternary700'),
      ppRibbonFold: alias('quaternary800'),
      bestOfferOfferBg: alias('primary100'),
      progressBarCircle: alias('primary300'),
      b2bGradientEnd: alias('primary200'),

      // Magazine Gradients
      magVenusGradStart: alias('primary400'),
      magVenusGradEnd: alias('secondary200'),
      magAthenaGradStart: alias('sales300'),
      magAthenaGradEnd: alias('secondary300'),
      magHerraGradStart: alias('primary500'),
      magHerraGradEnd: alias('quaternary400'),
      magNeutralGradStart: alias('primary300'),
      magNeutralGradEnd: alias('neutral600'),
      magYellow: alias('tertiary500'),
      magBlack: alias('neutral1200'),

      // Magazine Header
      magHeadFgBlack: alias('neutral1200'),
      magHeadFgWhite: alias('neutral150'),
      magHeadIconHover: alias('primary1300'),
      magHeadIconHoverNegative: alias('primary600'),
      magHeadBreadcrumb: alias('neutral1100'),
      magHeadBreadcrumbWhite: alias('neutral300'),
      magHeadBreadcrumbActiveWhite: alias('neutral150'),

      // Infographic Components
      infoChatUserPlaceholder: alias('primary1000'),
      infoTimeline: alias('primary300'),
      infoTimelineDotBg: alias('neutral100'),
      infoTimelineDot: alias('primary800'),

      // Search
      searchBgGradientStart: alias('primary200'),
      searchBgGradientEnd: alias('neutral150'),

      // Masthead
      mastheadTextColor: alias('neutral1300'),
      mastheadTextUnderline: alias('primary200'),
      mastheadGradient: alias('primary200'),

      // Reading list
      readingListStartGradient: alias('primary200'),
      readingListEndGradient: alias('neutral150'),

      // Rainbow components
      rainbowModalMobileBackdrop: alias('neutral1100'),
      rainbowModalBackdrop: alias('neutral100'),

      featureBlockTmReadingBg: alias('neutral1200'),
      featureBlockTmReadingFg: alias('neutral100'),

      // Black lists
      blackListBg: alias('neutral1300'),
      blackListColor: alias('neutral100'),
      blackListExclusive: alias('secondary1000'),

      slimPlaceholder: alias('neutral300'),
      slimPlaceholerGradient: alias('neutral600'),
      slimLightBlueInAllModes: alias('primary500'),

      liveBlogHeaderBackground: alias('neutral1300'),
      liveBlogHeader: alias('neutral200'),
      liveBlogBreadcrumbs: alias('primary800'),
      liveBlogKicker: alias('secondary600'),
      liveBlogActionBarIcon: alias('neutral300'),

      // Face Article
      faceArticleBgBtn: alias('neutral100'),
      faceArticleActiveBgBtn: alias('neutral500'),
      faceArticleColor: alias('neutral1200'),

      notForQuoteBg: alias('primary300'),
      notForQuoteOpenElementBg: alias('tertiary800'),
      notForQuoteOpenElementTitle: alias('primary1100'),
      notForQuoteOpenElementMainBg: alias('neutral150'),

      midrugBtn: alias('primary600'),

      timelineGradStart: alias('primary500'),
      timelineGradEnd: alias('neutral400'),
    },
    darkMode: {
      // Brand colors
      hdcBrand: HDC_BRAND_DM,
      htzBrand: HTZ_BRAND_DM,
      tmBrand: TM_BRAND_DM,

      // Base colors
      neutral100: '5,5,5',
      neutral150: '25,25,25',
      neutral200: '45,45,45',
      neutral300: '68,68,68',
      neutral400: '91,91,91',
      neutral500: '115,115,115',
      neutral600: '138,138,138',
      neutral700: '161,161,161',
      neutral800: '184,184,184',
      neutral900: '207,207,207',
      neutral1000: '219,219,219',
      neutral1100: '231,231,231',
      neutral1200: '243,243,243',
      neutral1300: '249,249,249',
      neutral1400: '255,255,255',

      primary100: '0,6,15',
      primary200: '0,11,33',
      primary300: '3,18,58',
      primary400: '11,29,90',
      primary500: '25,44,129',
      primary600: '47,63,175',
      primary700: '61,71,208',
      primary800: '75,78,241',
      primary900: '98,109,246',
      primary1000: HDC_BRAND_DM,
      primary1100: '147,166,255',
      primary1200: '170,189,255',
      primary1300: '193,210,255',
      primary1400: '208,224,255',
      primary1500: '220,231,250',

      secondary100: '82,14,14',
      secondary200: '98,16,16',
      secondary300: '114,19,18',
      secondary400: '135,28,25',
      secondary500: '157,38,33',
      secondary600: '179,49,42',
      secondary700: '200,60,49',
      secondary800: '222,71,58',
      secondary900: '254,93,76',
      secondary1000: '254,120,110',
      secondary1100: '255,145,140',
      secondary1200: '255,168,166',
      secondary1300: '255,189,189',

      tertiary100: '128,101,0',
      tertiary200: '153,121,0',
      tertiary300: '208,171,0',
      tertiary400: '237,201,0',
      tertiary500: '255,223,0',
      tertiary600: '255,232,35',
      tertiary700: '255,238,71',
      tertiary800: '255,243,106',
      tertiary900: '255,247,141',
      tertiary1000: '255,250,176',
      tertiary1100: '255,252,210',
      tertiary1150: '255,254,243',

      quaternary100: '0,5,3',
      quaternary200: '4,32,16',
      quaternary300: '10,56,26',
      quaternary400: '20,82,35',
      quaternary500: '35,107,44',
      quaternary600: '57,133,54',
      quaternary700: '88,163,86',
      quaternary800: '127,192,125',
      quaternary900: '174,222,171',
      quaternary1000: '201,237,200',
      quaternary1100: '218,246,219',
      quaternary1200: '237,255,239',

      sales100: '20,14,6',
      sales200: '59,40,11',
      sales300: '97,63,12',
      sales400: '135,85,9',
      sales500: '173,106,5',
      sales600: '212,127,1',
      sales700: '246,150,0',
      sales800: '247,180,57',
      sales900: '250,213,124',
      sales1000: '251,231,170',
      sales1100: '252,239,202',
      sales1200: '254,246,232',

      promoted300: '254,136,214',
      promoted800: '189,19,135',

      // Color aliases
      articlesSeriesLinks: alias('primary1100'),
      pageBg: alias('neutral100'),
      contentBg: alias('neutral150'),
      bodyText: alias('neutral1200'),
      bodyTextNegative: alias('neutral200'),
      shadow: alias('neutral100'),
      cardEmphasis: alias('neutral300'),
      // TODO: Change this
      cardBrand: alias('sales900'),
      cardGiftSubscription: alias('primary400'),
      cardGiftSubscriptionHover: alias('primary500'),
      lightIconOnDarkSurface: alias('neutral900'),
      lightTextOnDarkSurface: alias('neutral1000'),
      cardOutbrain: alias('neutral300'),
      selection: alias('tertiary200'),
      selectedText: alias('neutral150'),
      chipPressedBg: alias('primary300'),
      chipPressedBorder: alias('primary500'),
      exclusive: alias('secondary900'),
      featBlockModalBackdropMobile: alias('neutral300'),
      footerBg: alias('neutral200'),
      footerColor: alias('neutral1200'),
      fullCoverageListBgGradStart: alias('neutral300'),
      fullCoverageListBgGradEnd: alias('neutral400'),
      toggleCheckedBg: alias('neutral200'),
      safePurchaseBg: alias('neutral200'),
      breadcrumbAccent: alias('neutral100'),
      breadcrumb: alias('neutral200'),
      newsletterRegGradStart: alias('primary400'),
      newsletterRegGradEnd: alias('neutral200'),
      momListBg: alias('primary400'),
      popoverBorder: alias('neutral400'),
      popoverBg: alias('neutral150'),
      popoverInverseColor: alias('neutral100'),
      popoverInverseBg: alias('neutral1000'),
      topicHeadDivider: alias('primary600'),
      topNewsSection: alias('secondary900'),
      // TODO: change when hdc design is ready
      tldrBtn: alias('primary1000'),
      tldrBtnActive: alias('primary800'),
      tldrIcon: alias('neutral200'),
      salesBtn: alias('sales800'),
      surfaceDarkAllModes: alias('neutral200'),
      textLightAllModes: alias('neutral1300'),
      textDarkAllModes: alias('neutral200'),
      teaserGallerySelectedDot: alias('neutral1400'),
      teaserGalleryDots: alias('neutral1000'),
      markBg: alias('primary400'),
      sportsMatchEventsBottomBorder: alias('neutral400'),
      blockingElementGradStart: alias('neutral150'),
      blockingElementGradEnd: alias('primary400'),
      blockingElementSaleGradStart: alias('neutral150'),
      blockingElementSaleGradMid: alias('primary500'),
      blockingElementSaleGradEnd: alias('secondary1100'),
      rainbowIllustrationBrandLight: alias('neutral400'),
      rainbowIllustrationBrandMidLight: alias('neutral600'),
      rainbowBottomRulerBg1: alias('primary200'),
      rainbowBottomRulerBgMobile1: alias('primary300'),
      rainbowBottomRulerBg2: alias('primary1100'),
      rainbowBottomRulerBgMobile2: alias('primary1100'),
      rainbowBottomRulerBg3: alias('neutral150'),
      rainbowBottomRulerBgMobile3: alias('neutral200'),
      rainbowBottomRulerBg4: alias('primary400'),
      rainbowBottomRulerBgMobile4: alias('primary500'),
      rainbowBottomRulerSale1GradStart: alias('primary500'),
      rainbowBottomRulerSale1GradEnd: alias('secondary1100'),
      topStripBgAcquisition1: alias('primary1000'),
      topStripBgSubscribers1: alias('primary200'),
      weekendListGradientStart: alias('primary600'),
      bgLightAllModes: alias('neutral1400'),
      weekendListGradientMiddle: alias('primary400'),
      weekendListGradientEnd: alias('primary200'),
      positiveMarketsBarMobile: alias('primary1000'),
      negativeMarketsBarMobile: alias('secondary1000'),
      liveBlogBreakingNewsBg: alias('neutral150'),
      sheliIconColor: alias('neutral300'),
      positiveMarketsBar: alias('quaternary800'),
      positiveOnDarkSurface: alias('quaternary700'),
      negativeOnDarkSurface: alias('secondary900'),

      // Promotion Page
      ppSafePurchaseBg: alias('neutral200'),
      ppSpecialOfferBgGStart: alias('sales300'),
      ppSpecialOfferBgGEnd: alias('primary500'),
      ppRibbonFg: alias('quaternary700'),
      ppRibbonFold: alias('quaternary600'),
      bestOfferOfferBg: alias('primary300'),
      progressBarCircle: alias('primary600'),
      b2bGradientEnd: alias('primary200'),

      // Magazine Gradients
      magVenusGradStart: alias('primary1100'),
      magVenusGradEnd: alias('secondary1100'),
      magAthenaGradStart: alias('sales500'),
      magAthenaGradEnd: alias('secondary1300'),
      magHerraGradStart: alias('primary1100'),
      magHerraGradEnd: alias('quaternary800'),
      magNeutralGradStart: alias('primary400'),
      magNeutralGradEnd: alias('neutral700'),
      magYellow: alias('tertiary800'),
      magBlack: alias('neutral200'),

      // Magazine Header
      magHeadFgBlack: alias('neutral200'),
      magHeadFgWhite: alias('neutral1300'),
      magHeadIconHover: alias('primary500'),
      magHeadIconHoverNegative: alias('primary1200'),
      magHeadBreadcrumb: alias('neutral200'),
      magHeadBreadcrumbWhite: alias('neutral1300'),
      magHeadBreadcrumbActiveWhite: alias('neutral1100'),

      // Infographic Components
      infoChatUserPlaceholder: alias('primary800'),
      infoTimeline: alias('primary1400'),
      infoTimelineDotBg: alias('neutral150'),
      infoTimelineDot: alias('primary1000'),

      // Reading list
      readingListStartGradient: alias('primary400'),
      readingListEndGradient: alias('neutral150'),

      // Search
      searchBgGradientStart: alias('primary200'),
      searchBgGradientEnd: alias('neutral100'),

      // Masthead
      mastheadTextColor: alias('neutral150'),
      mastheadTextUnderline: alias('primary200'),
      mastheadGradient: alias('primary200'),

      // Rainbow components
      rainbowModalMobileBackdrop: alias('neutral150'),
      rainbowModalBackdrop: alias('neutral150'),

      featureBlockTmReadingBg: alias('neutral900'),
      featureBlockTmReadingFg: alias('neutral200'),

      // Black lists
      blackListBg: alias('neutral1300'),
      blackListColor: alias('neutral100'),
      blackListExclusive: alias('secondary900'),

      slimPlaceholder: alias('neutral200'),
      slimPlaceholerGradient: alias('neutral400'),
      slimLightBlueInAllModes: alias('primary1100'),

      liveBlogHeaderBackground: alias('neutral150'),
      liveBlogHeader: alias('neutral1200'),
      liveBlogBreadcrumbs: alias('primary1000'),
      liveBlogKicker: alias('secondary900'),
      liveBlogActionBarIcon: alias('neutral1100'),

      // Face Article
      faceArticleBgBtn: alias('neutral1400'),
      faceArticleActiveBgBtn: alias('tertiary800'),
      faceArticleColor: alias('neutral200'),

      notForQuoteBg: alias('primary400'),
      notForQuoteOpenElementBg: alias('primary700'),
      notForQuoteOpenElementTitle: alias('primary1000'),
      notForQuoteOpenElementMainBg: alias('neutral200'),

      midrugBtn: alias('primary700'),

      timelineGradStart: alias('primary1500'),
      timelineGradEnd: alias('neutral600'),
    },
  },
};

export default colorPalette;

/** @internal */
export function alias(linkTo: BaseColor): ColorAliasVar {
  return `var(${COLOR_PROP_PREFIX}${linkTo})`;
}
