import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import HdcLogo from '@haaretz/s-hdc-logo';
import HtzLink from '@haaretz/s-htz-link';
import HtzLogo from '@haaretz/s-htz-logo';
import MarkerLogo from '@haaretz/s-marker-logo';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import s9 from 'style9';

import Wrapper from './Wrapper';

import type { MainLogoVariant } from '../../Masthead.types';
import type { StyleExtend } from '@haaretz/s-types';
import type { StyleWithAtRules } from 'style9';

const c = s9.create({
  iconWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    marginInlineEnd: 'auto',
    marginInlineStart: 'auto',
    pointerEvents: 'auto',
  },
  defaultLogo: {
    fontSize: space(5),
    transition: 'all 0.2s ease-in-out',
    ...merge({
      ...mq({
        from: 'm',
        value: { display: 'inline', marginTop: space(0.3), fontSize: space(5.5) },
      }),
    }),
  },
  extendedIcon: {
    fontSize: space(8),
    ...merge({
      ...mq({
        from: 'm',
        value: { fontSize: space(12) },
      }),
    }),
  },

  dateAndTime: {
    display: 'none',
    ...merge({
      ...mq({ from: 'l', value: { display: 'inline', marginTop: space(0.3) } }),
    }),
  },
  searchPageLogo: {
    fontSize: space(7),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(8),
        },
      })
    ),
  },
  tmLogo: {
    fontSize: space(6),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(8),
        },
      })
    ),
  },
  tmExtendedLogo: {
    fontSize: space(8),
    ...merge(
      mq({
        from: 'l',
        value: {
          fontSize: space(11),
        },
      })
    ),
  },
  tmColorLogo: {
    color: color('tmBrand'),
  },
});

type LogoStyles = {
  isLabels?: boolean;
  tmStyleExtend?: StyleWithAtRules[];
  defaultStyleExtend?: StyleWithAtRules[];
  hdcStyleExtend?: StyleWithAtRules[];
};

function LogoPrimitive({
  tmStyleExtend = [],
  defaultStyleExtend = [],
  hdcStyleExtend = [],
  isLabels = false,
}: LogoStyles = {}) {
  return fork({
    default: (
      <HtzLogo
        kind={isLabels ? 'labels' : 'regular'}
        styleExtend={[c.defaultLogo, ...defaultStyleExtend]}
      />
    ),
    tm: (
      <MarkerLogo
        kind={isLabels ? 'labels' : 'regular'}
        styleExtend={[c.tmLogo, ...tmStyleExtend]}
      />
    ),
    hdc: (
      <HdcLogo
        kind={isLabels ? 'labels' : 'regular'}
        styleExtend={[c.defaultLogo, ...hdcStyleExtend]}
      />
    ),
  });
}

export function MastheadLogo({ variant }: { variant: MainLogoVariant }) {
  switch (variant) {
    case 'default':
    case 'liveBlog':
    case 'face':
    case 'podcast':
      return <LogoPrimitive tmStyleExtend={[c.tmColorLogo]} />;
    case 'magazine':
    case 'timeline':
    case 'readingList':
    case 'quotes':
      return <LogoPrimitive />;
    case 'labels':
      return <LogoPrimitive isLabels />;
    case 'extended':
      return (
        <LogoPrimitive defaultStyleExtend={[c.extendedIcon]} tmStyleExtend={[c.tmColorLogo]} />
      );
    case 'searchPage':
      <LogoPrimitive
        defaultStyleExtend={[c.searchPageLogo]}
        tmStyleExtend={[c.tmColorLogo, c.searchPageLogo]}
        hdcStyleExtend={[c.searchPageLogo]}
      />;

      return <LogoPrimitive />;
  }
}

type CommonProps = {
  variant: Exclude<MainLogoVariant, 'extended'>;
  styleExtend?: StyleExtend;
};

// TODO-Mor change to default

export default function Common({ variant, styleExtend }: CommonProps) {
  return (
    <Wrapper variant={variant} styleExtend={styleExtend}>
      <HtzLink
        className={s9(c.iconWrapper)}
        href={variant === 'labels' ? '/labels' : '/'}
        data-testid="masthead-logo-link"
      >
        <MastheadLogo variant={variant} />
        <VisuallyHidden>
          {fork({
            default: 'הארץ – חזרה לעמוד הבית',
            tm: 'The Marker – חזרה לעמוד הבית',
            hdc: 'Haaretz - back to home page',
          })}
        </VisuallyHidden>
      </HtzLink>
    </Wrapper>
  );
}
